@import '~styles/vars';

// Include the toastify library's base styling
@import 'node_modules/react-toastify/dist/ReactToastify';

.Toastify__toast {
  padding: $g--sm;
}

.Toastify__toast-body {
  display: flex;
  justify-content: center;
  align-items: center; 
  font-size: $f--body--md;
  line-height: 1.5;
  margin: $g--xs $g--sm;
}

.Toastify__close-button {
  width: 17px;
  height: 17px;
}

.Toastify__toast--success {
  background: $c--green;
}

.Toastify__toast--error {
  background: $c--red;
}