@import '~styles/vars';

@mixin field($background: $c--dark--05) {
  box-sizing: border-box;
  color: #1f336f;
  letter-spacing: 0.025em;
  background-color: $background;
  padding: $g--md;
  border-radius: $br--md;
  border: 1px solid transparent;
  -webkit-transition: box-shadow 150ms ease;
  transition: background 150ms ease;
}

@mixin field--focus {
  background: $c--dark--10;
}

@mixin field--invalid {
  border-color: $c--red;
}

@mixin field--webkit-autofill {
  background-color: #fefde5 !important;
}

@mixin field--reset {
  border: none !important;
  display: block !important;
  width: 100% !important;
  font-size: $f--body--md !important;
}
