@import '~styles/vars';

.card {
  margin-top: $g--lg;
  text-align: left;
}

.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 64px;
  margin-top: $g--lg;

  appearance: none;
  cursor: pointer;
  border: none;
  border-radius: $br--md;

  background: linear-gradient(110deg, $c--blue, $c--purple);
  background-size: 200%;
  box-shadow: 0 2px 10px 1px rgba($c--blue, 0.15), 0 5px 50px 0px rgba($c--purple, 0.05);

  color: $c--light;
  font-size: 16px;
  font-family: $f--body--family;
  font-weight: $f--header--weight;

  transition: background-position .3s;

  &:hover {
    background-position: 100% 50%;
  }
}

.paymentRequestButton {
  height: 64px;
  margin-top: $g--lg;

  // Add the extra specificity to prevent additional padding around the resulting stripe element
  padding: 0 !important;
}
