@import '~styles/vars';

.title {
  font-size: $f--header--md;
}

.blurb {
  text-align: left;
  color: $c--dark--60;
  font-size: $f--body--md;
  line-height: 1.5em;
  margin-top: $g--xl;
}

.link {
  color: $c--dark--80;
  text-decoration: underline;
  &:hover {
    color: $c--dark;
  }
}