@import '~styles/vars';

.view {
  background: $c--light;
  color: $c--dark;
  padding: $g--xl;
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;

  // Modify container display on larger screens to show a box shadow/framing artwork
  @media(min-width: $bp--sm) {
    margin: $g--lg;
    width: 480px;
    box-shadow: 0 2px 10px 1px rgba($c--dark, 0.15), 0 5px 50px 0px rgba($c--dark, 0.05);
  }
}

.topStripe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(95deg, $c--blue, $c--purple);
  box-shadow: 0 2px 10px 1px rgba($c--blue, 0.15), 0 5px 50px 0px rgba($c--purple, 0.05);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: $g--xl auto;
  
  color: $c--blue;
}

.blurb {
  color: $c--dark--60;
  font-size: $f--body--md;
  line-height: 1.5em;
  margin-top: $g--xl;
}

.footer {
  color: $c--dark--40;
  font-size: $f--body--xs;

  // Add white background to fill in background color
  background: $c--light;
  width: 100%;
  
  @media(min-width: $bp--sm) {
    background: transparent;
  }
}

.footerItem {
  margin: 0 $g--sm 0 0;
}

.footerLink {
  color: $c--dark--60;
  margin: $g--xs $g--sm;
  text-decoration: underline;
  display: block;

  @media(min-width: $bp--sm) {
    display: inline;
  }
}