@import '~styles/vars';

.app {
  align-items: center;
  background-color: $c--lightish;
  color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  text-align: center;

  // Allow contained elements to absolutely position themselves — e.g. the app header.
  position: relative;

  // Modify container display on larger screens to show a box shadow/framing artwork
  @media(min-width: $bp--sm) {
    min-height: 100vh;
    padding: $g--xl;
  }
}

.logo {
  width: auto;
  margin: $g--lg;
  height: 50px;

  svg {
    width: 100%;
    height: 100%;
  }
  
  @media(min-width: $bp--sm) {
    margin: 0 0 $g--lg 0;
    width: 253px;
    height: 80px;
  }
}

div, p, a, li, td { -webkit-text-size-adjust:none; }