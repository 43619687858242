@import '~styles/vars';

.invoiceItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: $f--body--lg;
  font-weight: $f--body--weight;
}

.name {
  flex: none;
}

.gap {
  flex: 1;
  // Optional border?
  border-bottom: 2px dotted $c--dark--05;
  margin: 0 $g--sm;
}

.amount {
  flex: none;
}

.interval {
  font-size: $f--body--sm;
}
