// Colors
$c--blue: #4268dd;
$c--red: hsl(355, 97%, 56%);
$c--green: hsl(155, 61%, 47%);
$c--purple: #293583;

// Colors -- Fonts
$c--dark: hsl(225, 56%, 28%);
$c--dark--05: rgba($c--dark, 0.05);
$c--dark--10: rgba($c--dark, 0.10);
$c--dark--30: rgba($c--dark, 0.30);
$c--dark--40: rgba($c--dark, 0.40);
$c--dark--50: rgba($c--dark, 0.50);
$c--dark--60: rgba($c--dark, 0.60);
$c--dark--80: rgba($c--dark, 0.80);

$c--light: hsl(0, 0%, 100%);
$c--light--75: rgba($c--light, .75);
$c--lightish: hsl(0, 20%, 97%);

// Sizing -- Gaps
$g--xs: 5px;
$g--sm: 10px;
$g--md: 14px;
$g--lg: 24px;
$g--xl: 38px;

// Sizing -- Breakpoints
$bp--xs: 480px;
$bp--sm: 600px;

// Sizing -- Icons
$i--sm: 18px;
$i--md: 24px;

// Fonts -- Headers
$f--header--xs: 11px;
$f--header--sm: 18px;
$f--header--md: 24px;
$f--header--lg: 42px;

$f--header--family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
$f--header--weight: 700;

// Fonts -- Body
$f--body--xs: 11px;
$f--body--sm: 12px;
$f--body--md: 14px;
$f--body--lg: 16px;

$f--body--family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

$f--body--weight: 700;

// Fonts -- Links
$f--link--weight: 700;

// Border-radius
$br--md: 4px;
$br--lg: 10px;
