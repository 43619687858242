@import '~styles/vars';

.divider {
  color: $c--dark--40;
  font-size: $f--header--xs;
  font-weight: $f--header--weight;
  height: 1em;
  margin-bottom: $g--lg;
  margin-top: $g--lg;
  position: relative;
  text-transform: uppercase;

  &:before {
    background: $c--dark--10;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

.dividerText {
  background: $c--light;
  left: 50%;
  padding: 0 $g--md;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}