@import '~styles/vars';

.account {
  font-size: $f--header--md;
  font-family: $f--header--family;
  font-weight: $f--header--weight;
  line-height: 1.25em;
  margin-top: $g--md;

  background: linear-gradient(95deg, $c--blue, $c--purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intro {
  text-align: left;
  background: $c--dark--05;
  border-radius: $br--lg;
  line-height: 1.5;
  font-size: $f--body--lg;

  padding: $g--lg;
  margin-top: $g--lg;

  @media(min-width: $bp--sm) {
    margin: $g--lg;
  }
}

.link {
  color: $c--dark;
  text-decoration: underline;
  font-weight: $f--link--weight;
}

// Push the stripe button down
.paymentRequestForm {
  margin-top: $g--md;
}
