@import '~styles/vars';

.title {
  font-size: $f--header--md;
}

.blurb {
  color: $c--dark--60;
  font-size: $f--body--md;
  line-height: 1.5em;
  margin-top: $g--xl;
}