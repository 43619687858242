.fade {
  &-enter {
    opacity: 0.01;
  }
  &-enter-active {
    opacity: 1;
    transition: all 300ms ease-out;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0.01;
    transition: all 300ms ease-in;
  }
}

.fadeGrowUp {
  &-enter {
    opacity: 0.01;
    transform: scale(0.9) translateY(50%);
  }
  &-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0%);
    transition: all 300ms ease-out;
  }
  &-exit {
    opacity: 1;
    transform: scale(1) translateY(0%);
  }
  &-exit-active {
    opacity: 0.01;
    transform: scale(0.9) translateY(50%);
    transition: all 300ms ease-out;
  }
}