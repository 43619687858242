@import '~styles/vars';
@import '~styles/mixins';

// Styling for the resulting stripe element that's created outside the scope of our css modules classname
.StripeElement { @include field; }
.StripeElement--focus { @include field--focus; }
.StripeElement--invalid { @include field--invalid; }
.StripeElement--webkit-autofill { @include field--webkit-autofill; }

// Elements made to match the Stripe element styling
.field {
  @include field;
  @include field--reset;

  &:focus {
    @include field--focus;
  }

  &::placeholder {
    color: $c--dark--40;
  }

  &::-webkit-autofill,
  &::-webkit-autofill:hover,
  &::-webkit-autofill:focus {
    @include field--webkit-autofill; 
  }
}

.field:read-only {
  background: transparent;
  cursor: pointer;
  outline: none;
}
